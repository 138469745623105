import axios from 'axios';

const BASE_URL = 'https://www.weather-app-api.codebrewnomad.com';
const GET_WEATHER = '/api/forecasts//%location%';

const axiosInstance = (cache = false) => {
    return new Promise((resolve) => {
        const instance = axios.create({
            baseURL: `${BASE_URL}`,
        });
  
        instance.interceptors.response.use(
          (response) => response,
          ({ response }) => {
            let message;
  
            switch (true) {
              case response.status > 499:
                message = {
                  code: 'server error',
                  message: 'Server Error. Please try again later.',
                  status: response.status,
                };
  
                break;

  
              default:
                message = {
                  code: 'unknown',
                  message: 'Unknown error',
                };
            }
  
            return Promise.reject(message);
          }
        );
  
        resolve(instance);
    });
  };
const WeatherApi = {
    getWeatherData: (locationName) => {
        return axiosInstance().then((instance) =>
          instance.get(GET_WEATHER.replace('%location%', locationName))
        );
      },
    }
export default WeatherApi;