import { useEffect, useState, useRef } from "react";
import WeatherApi from "../api/WeatherApi";
import { Card, Input, Space, Typography, Image } from "antd";
import { TERipple } from "tw-elements-react";
const { Search } = Input;

const Home = () => {
  const locationRef = useRef();
  const [isDataRequested, setIsDataRequested] = useState(false);
  const [weather, setWeather] = useState({});

  const getWeather = (location) => {
    WeatherApi.getWeatherData(location).then(({ data }) => {
      console.log(data);
      setWeather(data);
    });
  };
  useEffect(() => {
    if (!isDataRequested) {
      getWeather("Manila");
      setIsDataRequested(true);
    }
  }, [isDataRequested]);

  const searchLocation = (val, e) => {
    e.preventDefault();
    console.log(val);
    getWeather(val);
  };

  const dateBuilder = (d) => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    console.log(d.getTime());
    return `${day} ${date} ${month} ${year}`;
  };
  const isNightTime = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return typeof weather.sys != "undefined" &&
      currentTime >= weather?.sys?.sunrise &&
      currentTime < weather?.sys?.sunset
      ? false
      : true;
  };
  return (
    <div className={`weather-app${isNightTime() ? " night" : ""}`}>
      <Card
        title={
          <Space direction="vertical">
            <Search
              placeholder="search location"
              onSearch={searchLocation}
              style={{
                width: 200,
              }}
            />
          </Space>
        }
        style={{
          width: "fit-content",
        }}
        align="center"
      >
        {typeof weather.main != "undefined" ? (
          <div>
            <div className="location-box">
              <div className="location">
                <Image
                  width={100}
                  src={require(`../assets/${
                    (weather?.weather[0]?.main).toLowerCase() || "clear"
                  }.png`)}
                ></Image>
                <Typography.Title 
                  level={1}
                  style=
                  {{
                    margin: 0,
                  }}>
                  {Math.round(weather.main.temp)}°c
                </Typography.Title>
                <Typography.Title 
                  level={1}
                  style=
                  {{
                    margin: 0,
                  }}>
                  {weather.name}, {weather.sys.country}
                </Typography.Title>
              </div>
              <div className="date">{dateBuilder(new Date())}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};
export default Home;
